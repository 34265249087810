import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Watch } from 'vue-property-decorator';
import { Endpoint } from '../../shared/models/dto/endpoint';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import { ODataFilterItem, ODataFilterObject } from '../../shared/models/shared/odata-filter-object';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';
import AuthStore from '../../stores/auth-store';

@Component({
    components: {
        vDataTableFilter: require('@/views/_components/v-data-table-filter/v-data-table-filter.vue').default,
        editCreateEndpointDialog: require('@/views/endpoints/edit-create-endpoint-dialog.vue').default,
        columnSelectDialog: require('@/views/_components/column-select-dialog/column-select-dialog.vue').default
    }
})
export default class EndpointListComponent extends AuthComponentBase {
    currentRouteName: string = null;
    loading = true;
    showDialog = false;
    showColumnDialog = false;
    showFilter = false;
    availableColumnsAreSet = false;
    id = '';
    protocolName = '';

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['displayName']
    };

    totalCount = 0;
    items: Endpoint[] = [];

    headers = [];
    availableHeaders = [];

    returnHeaders() {
        return [
            { text: this.t('DisplayName'), value: 'displayName', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('EndpointType'), value: 'endpointType', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('EndpointServer'), value: 'endpointServer', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Actions'), value: 'actions', sortable: false, width: '160px', }
        ];
    }

    @Watch('options', { deep: true, immediate: false })
    onPaginationChanged() {
        this.getItems();
    }

    @Watch('$route', { immediate: true, deep: true })
    onUrlChange(newVal: any) {
        // if the route contains a param, this watcher makes sure the detailpage is opened
        this.currentRouteName = !this.currentRouteName ? newVal.name : this.currentRouteName;

        if (newVal && newVal.params && newVal.params.id) {
            this.edit(newVal.params.id, newVal.params.protocolName);
        } else {
            this.showDialog = false;
        }
    }

    created() {
        this.headers = this.returnHeaders();
        this.availableHeaders = this.returnHeaders();
    }

    mounted() {
        this.$appHub.$on('update-endpoint-list', this.getItems);
    }

    beforeDestroy() {
        //clean SignalR event
        this.$appHub.$off('update-endpoint-list', this.getItems);
    }

    getItems(filterItems?: ODataFilterItem[]) { // when called from v-data-table-filter, it provides the items
        this.loading = true;

        if (!filterItems) {
            filterItems = [];
        }

        filterItems.push({
            columnName: 'CompanyId',
            operator: FilterOperator.eq,
            value: AuthStore.getUser().companyId
        });

        const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
        const queryObj: ODataFilterObject = {
            filter: filterItems,
            pageIndex: page - 1,
            pageSize: itemsPerPage,
            $count: true
        };

        if (sortBy.length > 0 && sortBy[0]) {
            queryObj.sortBy = sortBy + ((sortDesc && sortDesc.length > 0 && sortDesc[0]) ? ' desc' : '');
        }

        const query = this.queryBuilder(queryObj);

        this.authService.get<IRestResponseDto<Endpoint[]>>(`/api/endpoints${query}`, false).then((response) => {
            const result: Endpoint[] = <Endpoint[]><unknown>response.content;

            this.items = result;

            this.totalCount = response.count;
            this.setAvailableColumns();
            this.loading = false;
        });
    }

    deleteItem(id: string) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/endpoints/${id}`).then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('Successful'));
                        this.getItems();
                    }
                    else {
                        this.swalToast(2000, 'error', this.t('SomethingWrong'));
                    }
                });
            }
        });
    }

    edit(id: string, protocolName: string) {
        this.id = id ? id : '';
        this.protocolName = protocolName ? protocolName : '';
        if (id && protocolName) {
            this.$router.push({ name: this.currentRouteName, params: { id: this.id.toString(), protocolName: protocolName } });
        }

        this.showDialog = true;
    }

    completed(reload: boolean) {
        this.$router.push({ name: this.currentRouteName });
        this.showDialog = false;
        if (reload) {
            this.getItems();
        }
    }

    setAvailableColumns() {
        if (this.items.length) {
            for (const [key, value] of Object.entries(this.items[0])) {
                if (!this.availableHeaders.some(e => e.value === key)) {
                    this.availableHeaders.push({
                        text: key !== 'id' ? this.t(key) : key,
                        value: key,
                        sortable: true,
                        width: 'auto',
                        filterOperator: this.returnFilterOperator(this.items[0], key),
                        inputType: this.returnPropType(this.items[0], key),
                    });
                }
            }
        }

        this.availableColumnsAreSet = true;
    }

    columnSelectCompleted(headers: any) {
        if (headers != null) {
            this.headers = [...headers];
        }

        this.showColumnDialog = false;
    }
}